import { ProductModel } from "../models/product.model";
import * as _ from 'lodash'
import { setDisplayedProducts, setProducts, setSelectedProductResult } from "../../../redux/product/productActions";
import { useDispatch, useSelector } from "react-redux";
import { toggleLoading } from "../../../redux/general/generalActions";
import { pmsEndPoint, visualizationEndPoint } from '../../../config/config.js'
import { pyStudioEndPoint } from '../../../config/config.js'

import axios from "../../../common/interceptors/api-client";
import { EventsService } from "../../../common/services/events.service";
import { Event, EventTypes, ProductUsageEvent } from "../../../common/models/events/event.model";

export class SpaceCatalogService {
  dispatch = useDispatch()
  fullCatalog: ProductModel[] = []
  displayedCatalog: ProductModel[] = []
  filters: any

  constructor() {
    this.displayedCatalog = this.fullCatalog
  }

  initCatalog() {
    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: pmsEndPoint + '/products'
    };

    axios.request(config)
      .then((response) => {
        this.dispatch(setProducts(response.data.data))
        this.fullCatalog = response.data.data
      })
  }

  async getProductBySKU(productSKU) {
    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: pmsEndPoint + '/products' 
    };

    const response = await axios.request(config)
    return response.data.data.find(product => product.sku === productSKU)
  }

  getProductsFilters(products) {
    const mergedSpecifics = products.reduce((acc, item) => {
      Object.keys(item.specifics??{}).forEach(key => {
        //@ts-ignore
        acc[key] = [...new Set([...(acc[key] || []), ...item.specifics[key]])];
      });
      return acc;
    }, {});
    return mergedSpecifics
  }

  filterProductsByName(name) {
    return this.displayedCatalog.filter(product => product.name.includes(name))
  }

  showBookmarksOnly(bookmarks) {
    this.dispatch(setDisplayedProducts(bookmarks))
  }

  async processProduct(visualizeData, space) {
    this.dispatch(toggleLoading());
    try {
      const data = JSON.stringify(visualizeData);
  
      // Helper function to make API calls
      const fetchImage = async (quality = '') => {
        const config = {
          method: 'post',
          maxBodyLength: Infinity,
          url: `${pyStudioEndPoint}/display/${space.id}${quality}`,
          headers: {
            'Content-Type': 'application/json',
          },
          responseType: 'blob', 
          data: data,
        };
        //@ts-ignore
        const response = await axios.request(config);
        return URL.createObjectURL(response.data);
      };
  
      // Fetch non-HQ image
      const imageUrlNoHQ = await fetchImage();
      this.dispatch(setSelectedProductResult(imageUrlNoHQ));
  
      // Fetch HQ image
        const imageUrlHQ = await fetchImage('?img_quality=high');
        this.dispatch(setSelectedProductResult(imageUrlHQ));
  
    } catch (error) {
      console.error('Error processing product:', error);
    } finally {
      this.dispatch(toggleLoading());
    }
  }
  

  async processRug(spaceId) {
    const API_URL = `${pyStudioEndPoint}/scene`;

    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `${API_URL}/${spaceId}`
    };

    return await axios.request(config)
  }

  async processSpaceForRugs(spaceId) {
    this.dispatch(toggleLoading())

    const API_URL = `${pyStudioEndPoint}/scene`;

    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `${API_URL}/${spaceId}`
    };

    const response = await axios.request(config)
    this.dispatch(toggleLoading())
    return response
  }


  async getProductMetadata(productId) {
    this.dispatch(toggleLoading())

    const API_URL = `${pmsEndPoint}/products`;

    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `${API_URL}/${productId}`
    };

    const response = await axios.request(config)
    this.dispatch(toggleLoading())
    return response
  }

  async getShareId(space, visualizeData) {
    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: pmsEndPoint + '/sharing',
      data: {
        space,
        visualizeData
      }
    };

    const response = await axios.request(config)
    return response.data
  }
}
