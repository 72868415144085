import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { SpacePicker } from './pages/space-picker.page';
import { SpaceVisualizer } from './pages/space-visualizer.page';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CookieConsent, { Cookies } from "react-cookie-consent";

import './all.scss'
import './App.scss'
import { IframeProvider } from './providers/IFrameContext';
import { ARVisualizer } from './pages/ar-visualizer.page';
import { UploadSpace } from './pages/upload-space.page';
import { SpaceShare } from './pages/space-share.page';
import { VendorThemeProvider } from './providers/VendorThemeProvider';
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import CookieConsentModal from './common/components/CookiesConsentModal';
/** Import main fonts */
//import './common/fonts/fonts.js'

function App() {
    const { i18n } = useTranslation();
    const [open, setOpen] = useState(false);
    const theme = useTheme()
    const isMDandUp = useMediaQuery(theme.breakpoints.up('md'));

    useEffect(() => {
        // Prevent pinch-to-zoom globally
        const handleGlobalTouchMove = (event) => {
          if (event.touches.length > 1) {
            event.preventDefault();
          }
        };
    
        document.addEventListener('touchmove', handleGlobalTouchMove, { passive: false });
    
        // Re-enable zoom for a specific div by its ID
        const allowZoomOnDiv = (event) => {
          const zoomableDiv = document.getElementById('TransformWrapper'); // Replace 'zoomableDivId' with the actual ID
    
          if (zoomableDiv && zoomableDiv.contains(event.target)) {
            // Allow zoom on this div (do nothing)
            alert("HERE")
          } else {
            // Prevent zoom elsewhere
            if (event.touches.length > 1) {
              event.preventDefault();
            }
          }
        };
    
        document.addEventListener('touchmove', allowZoomOnDiv, { passive: false });
    
        // Clean up event listeners on component unmount
        return () => {
          document.removeEventListener('touchmove', handleGlobalTouchMove);
          document.removeEventListener('touchmove', allowZoomOnDiv);
        };
      }, []);

      const handleAcceptAll = () => {
        const consentPreferences = {
            strictlyNecessary: true, 
            performanceCookies: true, 
            advertisingCookies: true, 
            personalizationCookies: true, 
        };
        localStorage.setItem('cookieConsent', JSON.stringify(consentPreferences));
      };
    

      const handleDecline = () => {
        const consentPreferences = {
            strictlyNecessary: false, 
            performanceCookies: false, 
            advertisingCookies: false, 
            personalizationCookies: false, 
        };
        localStorage.setItem('cookieConsent', JSON.stringify(consentPreferences));
      };


      const handleCustomize = () => {
        setOpen(true)
      };

      const onClose = () => {
        setOpen(false)
      }

    return (
        <div dir={i18n?.language === "ar" ? "rtl": "ltr"}>
            <CookieConsentModal open={open} onClose={onClose}/>
            <CookieConsent
                    location="bottom"
                    buttonText={<Box style={{cursor: "pointer", background: "white", width: "fit-content", padding: "4px 20px", borderRadius: 8}}>
                                    <Typography variant="body2" fontWeight={"bold"} color={"black"}>Accept All</Typography>
                                </Box>}
                    enableDeclineButton
                    declineButtonText={<Box style={{cursor: "pointer", background: "gray", width: "fit-content", padding: "4px 20px", borderRadius: 8}}>
                                            <Typography variant="body2" fontWeight={"bold"} color={"white"}>Decline</Typography>
                                        </Box>}
                    style={{ background: "#00000070", borderBottomRightRadius: 16, borderTopRightRadius: 16, width: isMDandUp ? "50vw":"100vw", marginBottom: 8 }}
                    buttonStyle={{ padding: 0, borderRadius: 8 }}
                    declineButtonStyle={{ padding: 0, borderRadius: 8 }}
                    onAccept={handleAcceptAll}
                    onDecline={handleDecline}
                >
                <Typography variant="h6" fontWeight={"bold"} >We value your privacy</Typography>
                <Typography variant="caption" >This website uses cookies to improve your experience and provide personalized content.</Typography> 
                <Typography variant="caption" >You can choose to accept all cookies or customize your preferences by clicking "Customize".</Typography>
                <Box onClick={handleCustomize} style={{marginTop: "10px", cursor: "pointer", background: "white", width: "fit-content", padding: "4px 20px", borderRadius: 8}}>
                    <Typography variant="body2" fontWeight={"bold"} color={"black"}>Customize</Typography>
                </Box>
            </CookieConsent>
            <IframeProvider>
                <BrowserRouter>
                    <div className="App">
                        <Routes>
                            <Route path="/visualizer" element={<VendorThemeProvider><SpacePicker /></VendorThemeProvider>} />
                            <Route path="/visualizer/:vendorAlias" element={<VendorThemeProvider><SpacePicker /></VendorThemeProvider>} />
                            <Route path="/visualizer/:vendorAlias/playground" element={<VendorThemeProvider><SpaceVisualizer /></VendorThemeProvider>} />
                            <Route path="/visualizer/playground" element={<VendorThemeProvider><SpaceVisualizer /></VendorThemeProvider>} />
                            <Route path="/ar/:vendorAlias/products/:productId" element={<ARVisualizer />} />
                            <Route path="/space-uploader/:vendorAlias" element={<VendorThemeProvider><UploadSpace /></VendorThemeProvider>} />
                            <Route path="/share/:vendorAlias/:sharingId" element={<VendorThemeProvider><SpaceShare /></VendorThemeProvider>} />
                        </Routes>
                    </div>
                </BrowserRouter>
            </IframeProvider>
        </div>
    );
}
export default App;