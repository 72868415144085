import { Alert, Box, Tooltip, Typography } from "@mui/material"
import { useDispatch, useSelector } from "react-redux"
import { selectFloor, selectWalls, setSelectedWalls, setSurfaces } from "../../../../../redux/surfaces/surfacesActions"
import { FullState } from "../../../../../redux/rootReducer"
import { useEffect, useState } from "react"
import { getFaIconsStyles } from "../../../../../common/styles/styles"
import { setSelectedProduct } from "../../../../../redux/product/productActions"
import { removeProductForSurface } from "../../../../../redux/surfaces/surfacesActions"
import { useTranslation } from "react-i18next"
import { SpaceCatalogService } from "../../../services/space-catalog.service"
import { VisualizeModel } from "../../../models/visualize.model"
import { SettingsService } from "../../../../../common/services/settings.service"
import { EventsService } from "../../../../../common/services/events.service"
import { Event, EventTypes, SurfaceTypeChangedEvent } from "../../../../../common/models/events/event.model"
import { getActiveSurface } from "../../../../../redux/surfaces/surfacesReducer"
import { KeepScale } from "react-zoom-pan-pinch"


export const SelectionIcons = (props) => {
    const dispatch = useDispatch()
    const { t } = useTranslation();

    const {isLoading} = useSelector((state: FullState) => state.generals)
    const {selectedWallsIds, isFloorSelected, isWallsSelected, isRugSelected, visualizeData, } = useSelector((state: FullState) => state.surfaces)
    const [spaceCatalogService] = useState<SpaceCatalogService>(new SpaceCatalogService())
    const {selectedSpace} = useSelector((state: FullState) => state.spacesPicker)
    const {products} = useSelector((state: FullState) => state.productsCatalog)

    const handleSelectWall = (wall) => {
        setSelectedProduct(undefined)
        if(!isWallsSelected)
            EventsService.push(new Event(EventTypes.SurfaceTypeChangedEvent, new SurfaceTypeChangedEvent(getActiveSurface({isFloorSelected, isWallsSelected, isRugSelected}), "walls")))
        dispatch(selectWalls())
        if(selectedWallsIds.findIndex(e => e === wall.id) >= 0){
            if(isWallsSelected)
                dispatch(setSelectedWalls(selectedWallsIds.filter(e => e !== wall.id)))
        } else {
            dispatch(setSelectedWalls([...selectedWallsIds, wall.id]))
        }
    }

    const handleSelectAllWalls = () => {
        const wallIds = selectedSpace.surfaceMarkers.walls.map(e => e.id)
        if(!areAllWallSelected()){
            if(!isWallsSelected)
                EventsService.push(new Event(EventTypes.SurfaceTypeChangedEvent, new SurfaceTypeChangedEvent(getActiveSurface({isFloorSelected, isWallsSelected, isRugSelected}), "walls")))
            dispatch(selectWalls())
            dispatch(setSelectedWalls(wallIds))
            dispatch(setSelectedProduct(undefined))
        } else {
            dispatch(setSelectedWalls([]))
        }
    }

    const handleRemoveProduct = () => {
        //searchParams.set('productId', product.id)
        dispatch(setSelectedProduct(undefined))
        let data = visualizeData

        if(isWallsSelected){
            selectedWallsIds.forEach(wall => {
                data = removeProductForSurface(data, wall, "walls")
            })
            dispatch(selectWalls())
        }
            
        if(isFloorSelected){
            data = removeProductForSurface(data, 0, "floor")
            dispatch(selectFloor())
        }
        dispatch(setSurfaces(data, selectedSpace.id))
        runProcess(data)
    }

    const runProcess = (data) => {
        spaceCatalogService.processProduct(data, selectedSpace)
    }

    const removeProductForSurface = (data: VisualizeModel, surfaceId, surfaceType) => {
        const existingSurface = data[surfaceType].find(surface => surfaceId === surface.surfaceKey)
        let surfaces = data[surfaceType]
        if(existingSurface)
            surfaces = data[surfaceType].filter(surface => surface !== existingSurface)
        
        return {
            ...data,
            [surfaceType]: surfaces.filter(surface => surface.surfaceKey !== surfaceId)
        }
    }

    const isSurfaceHavingAProduct = (surfaceType, surfaceId) => {
        return visualizeData[surfaceType].find(surface => surfaceId === surface.surfaceKey)
    }

    const isWallSelected = (wall) => {
        if(isWallsSelected) {
            return selectedWallsIds.findIndex(e => e === wall.id) >= 0
        }
        return false 
    }

    const areAllWallSelected = () => {
        return isWallsSelected && selectedSpace.surfaceMarkers.walls.length === selectedWallsIds.length
    }


    const hasFloor = () => selectedSpace.surfaces.find(surface => surface === "floor") && SettingsService.vendorHasFloor()
    const hasWalls = () => selectedSpace.surfaces.find(surface => surface === "walls") && SettingsService.vendorHasWalls()


    const vendorHasRugsProducts = () => (products.find( product => product.application_types.find(area => area.toLowerCase() === "interactive_floor")))
    const vendorHasFloorProducts = () => (products.find( product => product.application_types.find(area => area.toLowerCase() === "floor")))
    const vendorHasWallsProducts = () => (products.find( product => product.application_types.find(area => area.toLowerCase() === "walls")))

    const FloorPins = () => (<Box>
                    <i className={`fad fa-grid ${isFloorSelected ? "fa-beat": ""}`} 
                                                                onClick={(event) => {
                                                                    event.stopPropagation()
                                                                    dispatch(selectFloor())
                                                                    if(!isFloorSelected)
                                                                        EventsService.push(new Event(EventTypes.SurfaceTypeChangedEvent, new SurfaceTypeChangedEvent(getActiveSurface({isFloorSelected, isWallsSelected, isRugSelected}), "floor")))
                                                                }}
                                                                style={{
                                                                            ...getFaIconsStyles(), 
                                                                            borderRadius: "80px", 
                                                                            padding: 6, 
                                                                            paddingLeft: 6, 
                                                                            paddingRight: 6, 
                                                                            backgroundColor: "white", 
                                                                            borderColor: "#FFFFFF50", // Border color with 50% opacity
                                                                            WebkitBackgroundClip: "padding-box", /* for Safari */
                                                                            backgroundClip: "padding-box", /* for IE9+, Firefox 4+, Opera, Chrome */
                                                                            borderStyle: "solid",
                                                                            borderWidth: 4,
                                                                            color: isFloorSelected ? "white": "black", 
                                                                            boxShadow: isFloorSelected ? "1px 0 5px -2px #888": "none"
                                                                        }} >
                                                                            </i>
                                                                            {isFloorSelected && !isLoading && isSurfaceHavingAProduct("floor", 0) && <i className={`fad fa-circle-xmark`} 
                                                                                onClick={(event) => {
                                                                                    event.stopPropagation()
                                                                                    handleRemoveProduct()
                                                                                }}
                                                                                style={{
                                                                                    ...getFaIconsStyles(), 
                                                                                    left: -10,
                                                                                    top: -10,
                                                                                    boxShadow: "1px 0 5px -2px #888",
                                                                                    //@ts-ignore
                                                                                    "--fa-secondary-color": "red",
                                                                                    //@ts-ignore
                                                                                    "--fa-primary-color": "white",
                                                                                    "--fa-secondary-opacity": 1
                                                                                    }} ></i>}
                </Box>)

            const WallsPins = ({wall}) => (<Box><i className={`fad fa-brush ${isWallSelected(wall) ? "fa-beat": ""}`} 
                onClick={(event) => {
                        event.stopPropagation()
                        handleSelectWall(wall)
                    }
                }
                                            style={{...getFaIconsStyles(), borderRadius: "80px", 
                                                        padding: 6, 
                                                        paddingLeft: 7, 
                                                        paddingRight: 7, 
                                                        backgroundColor: "white", 
                                                        borderColor: "#FFFFFF50", // Border color with 50% opacity
                                                        //@ts-ignore
                                                        WebkitBackgroundClip: "padding-box", /* for Safari */
                                                        backgroundClip: "padding-box", /* for IE9+, Firefox 4+, Opera, Chrome */
                                                        borderWidth: 4,
                                                        borderStyle: "solid",
                                                        color: isWallsSelected && isWallSelected(wall)  ? "white": "black", 
                                                        boxShadow: isWallsSelected && isWallSelected(wall) ? "1px 0 5px -2px #888" : "none"}} ></i> 
            {isWallSelected(wall) && !isLoading && isSurfaceHavingAProduct("walls", wall.id) && <i className={`fad fa-circle-xmark`} 
                                    onClick={(event) => {
                                        event.stopPropagation()
                                        handleRemoveProduct()
                                    }}
                                    style={{
                                        ...getFaIconsStyles(), 
                                        left: "-10px",
                                        top: "-10px",
                                        boxShadow: "1px 0 5px -2px #888",
                                        //@ts-ignore
                                        "--fa-secondary-color": "red",
                                        //@ts-ignore
                                        "--fa-primary-color": "white",
                                        "--fa-secondary-opacity": 1
                                        }} ></i>}

            {/* ALL WALLS ICON */}
            {wall.id === 0 && selectedSpace.surfaceMarkers.walls.length>1 && (
                <Tooltip title={`Select All walls`} arrow placement="right">
                    <Box className="surfacePin" sx={{color: "white", position: "absolute", cursor: "pointer"}} 
                        onClick={(event) => {
                            event.stopPropagation()
                            handleSelectAllWalls()}
                        }>
                        <Typography display={"flex"} alignItems={"center"}
                                    fontStyle={"bold"} 
                                    style={{ 
                                        borderRadius: "20px", 
                                        padding: 2, 
                                        paddingLeft: 10, 
                                        paddingRight: 10, 
                                        fontSize: 14, 
                                        backgroundColor: areAllWallSelected() ? "black" : "white",
                                        boxShadow: "1px 0 5px -2px #888",
                                        color: areAllWallSelected() ? "white": "black"}}>
                            {t('VISUALIZER.BUTTONS.WALLS')} 
                        </Typography>
                    </Box>
                </Tooltip>
            )}</Box>)

    return (<>{selectedSpace && props.scale !== 0 && <Box sx={{ 
                position: "absolute",
                width: selectedSpace.width * props.scale,
                height: selectedSpace.height * props.scale
            }} onClick={(event) => {
                    if(props.isOnCompareProducts)
                        props.setSelectedSide()
                    else
                        props.setAreIconsVisible(!props.areIconsVisible)
                }
            }>
            { <Box sx={{
                opacity: !props.areIconsVisible ? "0" : "1",
                visibility: !props.areIconsVisible ? "hidden": "visible",
                transition: "opacity 0.3s, visibility 0.3s", // Animation duration
            }}>
                {/* FLOOR ICON */}
                {hasFloor() && vendorHasFloorProducts() &&
                     <Box className="surfacePin" 
                                                sx={{   color: "white",  
                                                        position: "absolute", 
                                                        top: selectedSpace.surfaceMarkers.floor[0].y * props.scale, 
                                                        left: selectedSpace.surfaceMarkers.floor[0].x * props.scale, 
                                                        cursor: "pointer"
                                                    }}>
                        {!props.isOnCompareProducts && <KeepScale><FloorPins/></KeepScale>}
                        {props.isOnCompareProducts && <FloorPins/>}
                        </Box>}

                {/* WALLS ICONs */}
                {hasWalls() && vendorHasWallsProducts() && selectedSpace.surfaceMarkers.walls.map((wall, index) => (<div 
                    //@ts-ignore
                    key={index}>
                        <Box className="surfacePin" display="flex"  alignItems={"center"} 
                                                                    flexDirection={"row"} sx={{
                                                                                                color: "white", 
                                                                                                position: "absolute", 
                                                                                                top: wall.y * props.scale, 
                                                                                                left: wall.x * props.scale, 
                                                                                                cursor: "pointer"
                                                                                            }}>
                            {!props.isOnCompareProducts && <KeepScale><WallsPins wall={wall} /></KeepScale>}
                            {props.isOnCompareProducts && <WallsPins wall={wall} />}
                        </Box>
                    </div>
                ))}
            </Box>}
            
        </Box>}</>)
}