import { TransformWrapper } from "react-zoom-pan-pinch"
import { InitialSpaceMobile } from "./Mobile/_InitialSpaceMobile"
import { InitialSpaceWeb } from "./Web/_InitialSpaceWeb"
import { Box, useTheme } from "@mui/material";
import { useMediaQuery } from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { FullState } from "../../../../../redux/rootReducer";
import { RightPoppinBar } from "../Common/RightPoppinBar";
import { closedDrawerHeight } from "../../../../../common/components/SwipeableDrawer";

export const InitialSpace = (props) => {
    const [areIconsVisible, setAreIconsVisible] = useState(true)
    const theme = useTheme();
    const isMDandDown = useMediaQuery(theme.breakpoints.between('xs', 'lg'));
    const isMDandUp = useMediaQuery(theme.breakpoints.up('lg'));
    const {drawerHeight, isMobileDrawerOpen} = useSelector((state: FullState) => state.generals)
    const [height, setHeight] = useState<any>();


    return (
        <Box     
            sx={isMDandDown ? {
            width: "100%",
        } : {width: "100%"}}  >
            <TransformWrapper   
                                initialScale={1} 
                                minScale={1} 
                                panning={{
                                    disabled: props.scale === 1, 
                                    velocityDisabled: true
                                }} 
                                centerOnInit={true}
                                alignmentAnimation={{ sizeX: 0, sizeY: 0 }}
                                limitToBounds={true}
                                centerZoomedOut={true}
                                smooth={false}
                                disablePadding={true}
                                wheel={{ step: 0.1 }}
                                doubleClick={{ disabled: true }}
                            >
                {isMDandUp && <RightPoppinBar areIconsVisible={areIconsVisible} />}

                {isMDandUp && <InitialSpaceWeb          scale={props.scale}
                                                        scaleHeight={props.scaleHeight}
                                                        scaleWidth={props.scaleWidth}
                                                        selectedSpaceResult={props.selectedSpaceResult}
                                                        selectedSpace={props.selectedSpace}
                                                        areIconsVisible={areIconsVisible} 
                                                        setAreIconsVisible={setAreIconsVisible}
                                                    />}
                {isMDandDown && <InitialSpaceMobile     id="TransformWrapper"
                                                        scale={props.scale}
                                                        scaleHeight={props.scaleHeight}
                                                        scaleWidth={props.scaleWidth}
                                                        selectedSpaceResult={props.selectedSpaceResult}
                                                        selectedSpace={props.selectedSpace}
                                                    />}
            </TransformWrapper>
        </Box>
    )
}