import { Badge, Box, Grid, Popover, Tooltip, Typography } from "@mui/material"
import { useDispatch, useSelector } from "react-redux"
import { FullState } from "../../../../../../redux/rootReducer"
import { getFaIconsStyles } from "../../../../../../common/styles/styles"
import { useTranslation } from "react-i18next"
import { SpaceCatalogService } from "../../../../services/space-catalog.service"
import { useContext, useEffect, useState } from "react"
import { EventsService } from "../../../../../../common/services/events.service"
import { Event, EventTypes, ProductResultDownloadedEvent, ProductVisitVendorPageEvent } from "../../../../../../common/models/events/event.model"
import { getActiveSurface } from "../../../../../../redux/surfaces/surfacesReducer"
import { SettingsService } from "../../../../../../common/services/settings.service"
import ardealyLogo from "../../../../../../assets/logo-white.png"
import { Product } from "../../../Product/Product"
import { useLocation } from "react-router-dom"
import { VendorThemeContext } from "../../../../../../providers/VendorThemeProvider"
import { ShareSpaceModal } from "../../../Dialogs/ShareSpaceModal"

export const ResultActionsWeb = (props) => {
  const dispatch = useDispatch()
  const {vendorAlias} = useContext(VendorThemeContext);
  const { t } = useTranslation();
  const {selectedProductResult, selectedProduct, products} = useSelector((state: FullState) => state.productsCatalog)
  const {isFloorSelected, isWallsSelected, isRugSelected, visualizeData} = useSelector((state: FullState) => state.surfaces)
  const {selectedSpace} = useSelector((state: FullState) => state.spacesPicker)
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const location = useLocation()
  const [isShareModalOpen, setIsShareModalOpen] = useState(false)
  const [spaceCatalogService] = useState<SpaceCatalogService>(new SpaceCatalogService())
  const [shareId, setShareId] = useState()

  const [selectedProducts, setSelectedProducts] = useState<any>({
    floor: [],
    walls: []
  })
  const [numberOfSelectedProducts, setNumberOfSelectedProducts] = useState(0)

  const {isLoading} = useSelector((state: FullState) => state.generals)

  const [displayedProduct, setDisplayedProduct] = useState(undefined)
  const [productQRCodeURL, setProductQRCodeURL] = useState("")

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  useEffect(() => {
    if(selectedProduct){
      setProductQRCodeURL(window.location.protocol + "//" + window.location.host +"/ar/"+ SettingsService.settings.company + "/products/" + selectedProduct.id)
      setDisplayedProduct(selectedProduct)
    }
  },[selectedProduct])

  useEffect(() => {
    setNumberOfSelectedProducts(0)
    setSelectedProducts({
      floor: [],
      walls: []
    })
  },[selectedSpace])

  useEffect(() => {
    setSelectedProducts({
      floor: visualizeData.floor
        ? Array.from(new Set(visualizeData.floor.map(e => {
            return products.find(product => product.id === e.productId);
          }).filter(Boolean))) // Filter out undefined in case product is not found
        : [],
      walls: visualizeData.walls
        ? Array.from(new Set(visualizeData.walls.map(e => {
            return products.find(product => product.id === e.productId);
          }).filter(Boolean))) // Filter out undefined in case product is not found
        : []
    });
  }, [visualizeData])

  useEffect(() => {
    if(selectedProducts){
      setNumberOfSelectedProducts(selectedProducts.floor?.length + selectedProducts.walls?.length)
    }
  }, [selectedProducts])
  

  const handleDownloadResult = () => {
    if(selectedProduct){
      EventsService.push(new Event(EventTypes.ProductResultDownloadedEvent, new ProductResultDownloadedEvent(visualizeData)))
      document.getElementById("download").click(); 
    }
  }

  const handleShareSpaceResult = async() => {
    const shareId = await spaceCatalogService.getShareId(selectedSpace._id, visualizeData)
    setShareId(shareId)
    setIsShareModalOpen(true)
  }
  

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

    return (<>
        <Box alignItems={"center"} position={"relative"} display="flex" marginBottom={2} justifyContent={"space-between"}  marginTop={1}
              paddingLeft={2} borderRadius={2} color={"black"} boxShadow={'1px 0 5px -1px #88888850'} bgcolor={"white"} height={"50px"}
              sx={{borderTopRightRadius: 0, borderBottomRightRadius: 0}}
              onClick={() => {}} >
              {displayedProduct && 
                <Box display={"flex"} alignItems={"center"} >
                  <Tooltip title={"See products applied in the visualizer"} arrow placement="bottom" >
                    <Badge badgeContent={numberOfSelectedProducts} color="primary">
                      <img src={ardealyLogo} height={"30px"} style={{filter: "brightness(0) saturate(100%)", cursor: "pointer"}} onClick={(e) => handleClick(e)} />
                    </Badge>
                  </Tooltip>
                  <Box display={"flex"} flexDirection={"column"} marginLeft={2}>
                    <Typography variant='caption' sx={{wordBreak: "break-word", color: "gray"}} lineHeight={1}>{displayedProduct.brand}</Typography>
                    <Typography variant='body1' sx={{wordBreak: "break-word"}}>{displayedProduct.name}</Typography>
                  </Box>
                </Box>}
                <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                    sx={{marginTop: 1, marginLeft: -2}}
                  >
                    <Grid container width={selectedProducts?.walls.length > 0 && selectedProducts?.floor.length > 0 ? "800px" : "400px"}>
                      {selectedProducts?.floor.length > 0 && <Grid item md={selectedProducts?.walls.length > 0 ? 6 : 12} >
                        <Typography paddingTop={1} paddingInline={1} variant='body2'>Floor products</Typography>
                        {selectedProducts?.floor.map((product, index) => (
                            <Box paddingTop={1} paddingInline={1} key={index}>
                              <Product
                                  item={product}
                                  isShownAsList={true}
                                  handleProductSelect={() => {}}
                                  handleSiblingSelected={() => {}}
                                  view={"web"}
                                  showActions={false}
                              />
                            </Box>
                          ))}
                      </Grid>}
                      {selectedProducts?.walls.length > 0 && <Grid item md={selectedProducts?.floor.length > 0 ? 6 : 12} >
                        <Typography paddingTop={1} paddingInline={1} variant='body2'>Walls products</Typography>
                        {selectedProducts?.walls.map((product, index) => (
                            <Box paddingTop={1} paddingInline={1} key={index}>
                              <Product
                                  item={product}
                                  isShownAsList={true}
                                  handleProductSelect={() => {}}
                                  handleSiblingSelected={() => {}}
                                  view={"web"}
                                  showActions={false}
                              />
                            </Box>
                          ))}
                      </Grid>}
                    </Grid>
                    
                  </Popover>
              <Box display={"flex"} >

                <Box display={"flex"} justifyContent={"center"} alignItems={"center"} 
                    sx={{ cursor: (selectedProductResult || isRugSelected) && !isLoading ? 'pointer': "not-allowed", backgroundColor: "primary.main", padding: 1, borderRadius: 2}} 
                    marginInline={1} onClick={() => {
                      window.open(displayedProduct.link, '_blank')
                      EventsService.push(new Event(EventTypes.ProductVisitVendorPageEvent, 
                          new ProductVisitVendorPageEvent(
                            displayedProduct, selectedSpace, getActiveSurface({isFloorSelected, isWallsSelected, isRugSelected}), null, false
                          )
                      ))
                    }}>
                  {/* <Compare fontSize='medium' sx={{color: selectedProductResult ? "black": "gray"}} /> */}
                  <i className="fad fa-globe-pointer" style={{...getFaIconsStyles(), "--fa-primary-color": "white", "--fa-secondary-color": "white"} as any} ></i>
                  <Typography variant="caption" fontWeight={"bold"} marginLeft={1} 
                      sx={{color: (selectedProductResult || isRugSelected) ? "white": "gray"}}>{t("VISUALIZER.BUTTONS.GO_TO_PRODUCT_PAGE")}</Typography>
                </Box>

                <Box display={"flex"} justifyContent={"center"} alignItems={"center"} 
                    sx={{ cursor: (selectedProductResult || isRugSelected) && !isLoading ? 'pointer': "not-allowed" }} 
                    marginInline={1} onClick={() => {
                      window.open(displayedProduct.link, '_blank')
                      EventsService.push(new Event(EventTypes.ProductVisitVendorPageEvent, 
                          new ProductVisitVendorPageEvent(
                            displayedProduct, selectedSpace, getActiveSurface({isFloorSelected, isWallsSelected, isRugSelected}), null, false
                          )
                      ))
                    }}>
                  {/* <Compare fontSize='medium' sx={{color: selectedProductResult ? "black": "gray"}} /> */}
                  {/* <i className="fad fa-globe-pointer" style={getFaIconsStyles()} ></i>
                  <Typography variant="caption" fontWeight={"bold"} marginLeft={1} 
                      sx={{color: (selectedProductResult || isRugSelected) ? "black": "gray"}}>{t('VISUALIZER.BUTTONS.GO_TO_PRODUCT_PAGE')}</Typography> */}
                </Box>


                <Box display={"flex"} justifyContent={"center"} alignItems={"center"} 
                    sx={{ cursor: (selectedProductResult || isRugSelected) && !isLoading ? 'pointer': "not-allowed" }} 
                    marginInline={1} 
                     onClick={handleShareSpaceResult}>
                  {/* <Compare fontSize='medium' sx={{color: selectedProductResult ? "black": "gray"}} /> */}
                  <i className="fad fa-share-from-square" style={getFaIconsStyles()} ></i>
                  <Typography variant="caption" fontWeight={"bold"} marginLeft={1} 
                      sx={{color: (selectedProductResult || isRugSelected) ? "black": "gray"}}>{t("VISUALIZER.BUTTONS.SHARE")}</Typography>
                </Box>

                <Box display={"flex"} justifyContent={"center"} alignItems={"center"} 
                    sx={{ cursor: (selectedProductResult || isRugSelected) && !isLoading ? 'pointer': "not-allowed" }} 
                    marginInline={1} marginRight={3} 
                     onClick={() => handleDownloadResult()}>
                  {/* <Compare fontSize='medium' sx={{color: selectedProductResult ? "black": "gray"}} /> */}
                  <i className="fad fa-download" style={getFaIconsStyles()} ></i>
                  <Typography variant="caption" fontWeight={"bold"} marginLeft={1} 
                      sx={{color: (selectedProductResult || isRugSelected) ? "black": "gray"}}>{t("VISUALIZER.BUTTONS.DOWNLOAD")}</Typography>
                </Box>
              </Box>
        </Box>
        <a href={selectedProductResult ? selectedProductResult:""} id="download" download="Image.png"></a>
        <ShareSpaceModal URL={window.location.host + '/share/'+vendorAlias+'/'+shareId} isOpen={isShareModalOpen} handleClose={() => setIsShareModalOpen(false)} />
        </>
    )
}
