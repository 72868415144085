import { CloseOutlined, FavoriteBorderOutlined, GridView } from "@mui/icons-material"
import { Box, Grid, Tooltip, Typography } from "@mui/material"
import { hideCatalog, toggleBookmarks } from "../../../../../redux/general/generalActions"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { FullState } from "../../../../../redux/rootReducer"
import { SpaceCatalogService } from "../../../services/space-catalog.service"
import { SearchBar } from "../_SearchBar"
import { WebPoppingBar } from "./_WebPoppingBar"
import { ProductList } from "../../Product/ProductList"

const [defaultWidth] = [300];

export const WebSideBar = () => {
  const {displayedProducts} = useSelector((state: FullState) => state.productsCatalog)

    return (
      <Box display="flex" flexDirection={"column"} width={"25vw"}>
          { <WebPoppingBar /> }
            <Box sx={{ borderRadius: 2, padding: 1, boxShadow: '1px 0 5px -1px #88888850', backgroundColor: "white", marginTop: 2,
                      position: "relative"}}>
                <SearchBar />
                <ProductList view="web" />
            </Box>
      </Box>
    )
}